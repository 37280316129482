import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { fetchData, updateData } from "../../helpers/databaseHelpers";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import '../../Website/assets/css/status.css';

const Status = () => {
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const { txnid } = useParams();

  const [paymentStatus, setPaymentStatus] = useState({
    user: {},
    payment: {},
    plan: {},
    isLoading: true,
    isFailed: false,
  });

  const calculateExpiryDate = (planBuyDate, durationMonths) => {
    const buyDate = new Date(planBuyDate);
    if (isNaN(buyDate.getTime())) {
      throw new Error("Invalid date format");
    }
    buyDate.setMonth(buyDate.getMonth() + durationMonths);
    return buyDate.toISOString().split('T')[0];
  };

  useEffect(() => {
    if (!token) {
      navigate('/');
      return;
    }

    const fetchPaymentStatus = async () => {
      try {
        const [userData] = await fetchData("*", "users", { token });
        const [paymentData] = await fetchData("*", "phonepaypayment", { merchantTransactionId: txnid });

        if (!userData || !paymentData) throw new Error("Invalid data");

        const [planData] = await fetchData("*", "plan", { name: paymentData.plan });

        const response = await fetch(`${baseUrl}/phonepayCheckStatus`, {
          method: "POST",
          body: new URLSearchParams({ merchantTransactionId: txnid }),
        });

        const statusData = await response.json();
        if (!statusData.ok) {
          setPaymentStatus((prev) => ({ ...prev, isFailed: true }));
          return;
        }

        const no_of_contact = paymentData.no_of_contact;

        const no_of_message = 50;

        const plan_expiry_date = calculateExpiryDate(paymentData.date, planData.duration_month);

        await updateData(
          "users",
          {
            'account_type': 'Pro',
            plan: planData.name,
            plan_buy_date: paymentData.date.split('T')[0],
            plan_expiry_date,
            no_of_contact,
            no_of_message,
            'primium_badge': planData.primium_badge,
            'relationship_manager': planData.relationship_manager,
            'nri_rishtey': planData.nri_rishtey,
            'vip_treatment': planData.vip_treatment,
            'payment_txn_id': paymentData.merchantTransactionId
          },
          { uniquecode: userData.uniquecode }
        );

        
        setPaymentStatus({
          user: userData,
          payment: paymentData,
          plan: planData,
          isLoading: false,
          isFailed: false,
        });
      } catch (error) {
        toastr.error("An error occurred while fetching payment status.");
        setPaymentStatus((prev) => ({ ...prev, isFailed: true, isLoading: false }));
      }
    };

    fetchPaymentStatus();
  }, [token, txnid]);

  if (paymentStatus.isLoading) return <p>Loading...</p>;

  if (paymentStatus.isFailed) {
    return (
      navigate('/paln')
    );
  }

  const { payment, plan } = paymentStatus;

  return (
    <section className="w90 contactus paynow fcs-display m_auto">
      <div className="container_pri">
        <div className="printer-top"></div>
        <div className="paper-container">
          <div className="printer-bottom"></div>
          <div className="paper">
            <div className="main-contents">
              <div className="success-icon">&#10004;</div>
              <div className="success-title">Payment Confirmed</div>
              <div className="success-description">
                Your payment for ₹{payment.amount_before_gst} has been received.
              </div>
              <div className="order-details">
                <div className="order-number-label">Payment ID</div>
                <div className="order-number">{payment.merchantTransactionId}</div>
              </div>
              <div className="order-details">
                <div className="order-number-label">Plan</div>
                <div className="order-number">{plan.name}</div>
              </div>
              <div className="order-footer">
                <Link to='/dashboard'>Close</Link>
              </div>
            </div>
            <div className="jagged-edge"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Status;
