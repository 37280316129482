import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate , useLocation } from "react-router-dom";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import './style..css';

import
{
    fetchCommunity,
    fetchMaritalStatus,
    fetchData,
    fetchCountry,
    fetchState,
    fetchCity,
    fetchIncomeRange
} from "../../helpers/databaseHelpers";

let MyMatches = () =>
{
    let baseUrl = process.env.REACT_APP_BACKEND_URL;

    let navigate = useNavigate();
    const token = localStorage.getItem( "authToken" );

    if ( !token )
    {
        console.log( "No token found. Redirecting to login." );
        navigate( "/" );
    }

 

    // height 
    let [ height, setHeight ] = useState( '0' );


    // User 
    let [ userDeatils, setUserDetails ] = useState( [] );

    let [ state, setState ] = useState( 'unset' );
    let [ country, setCountry ] = useState( 'unset' );
    let [ city, setCity ] = useState( 'unset' );
    let [ maritalStatus, setMaritalStatus ] = useState( 'unset' );
    let [ age, setAge ] = useState( 'unset' );
    let [ spiritualPaths, setSpiritualPaths ] = useState( 'unset' );
    let [ income, setIncome ] = useState( 'unset' );
    let [ newMatches, setNewMatches ] = useState( [] );

    // Community
    let [ communityList, setCommunityList ] = useState( [] );

    // Age 
    let [ startAge, setStartAge ] = useState( 18 );
    let [ endAge, setEndAge ] = useState( 60 );


    // Marital Status 
    let [ maritalList, setMaritalList ] = useState( [] );


    // Country 
    let [ countryList, setCountryList ] = useState( [] );

    // State 
    let [ stateList, setStateList ] = useState( [] );

    // City 
    let [ cityList, setCityList ] = useState( [] );
    
    // Income 
    let [ incomeList, setIncomeList ] = useState( [] );
    
    // Get Connect  
    let [ connectList, setConnectList ] = useState( [] );



    // Get Partner Perfercese  
    let [ partner_preferences, setPartnerPreference ] = useState( [] );


    useEffect( () =>
    {
        const loadDetails = async () =>
        {
            try
            {
                const whereObj = { token: token };
                const userData = await fetchData( "*", "users", whereObj );
                setUserDetails( userData[ 0 ] );
            } catch ( error )
            {
                console.error( "Error loading user details:", error );
            }
        };
        loadDetails();
    }, [ token ] );


    useEffect( () =>
    {
        let fetchNewMatches = async () =>
        {
            await fetch( `${ baseUrl }/myMatches`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify( { state, country, city , maritalStatus, age, spiritualPaths, startAge, endAge, user: userDeatils.uniquecode , partner_preferences , income } ),
            } ).then( response =>
            {
                if ( !response.ok )
                {
                    throw new Error( "Network response was not ok" );
                }
                return response.json();
            } )
                .then( data =>
                {
                    setNewMatches( data.data );
                } )
                .catch( error =>
                {
                    console.error( "There was a problem with the fetch operation:", error );
                } );
        }

        fetchNewMatches();
    }, [ state, country, city , maritalStatus, age, spiritualPaths, startAge, endAge, userDeatils , connectList ] )




    // fetch Community

    useEffect( () =>
    {
        const load = async () =>
        {
            try
            {
                const communitiesData = await fetchCommunity();
                setCommunityList( communitiesData );
            } catch ( error )
            {
                console.error( "Error loading community:", error );
            }
        };

        load();

    }, [] )



    // fetch Marital Status

    useEffect( () =>
    {
        const load = async () =>
        {
            try
            {
                const maritalData = await fetchMaritalStatus();
                setMaritalList( maritalData );
            } catch ( error )
            {
                console.error( "Error loading marital status:", error );
            }
        };

        load();

    }, [] )


    // fetch Country

    useEffect( () =>
    {
        const load = async () =>
        {
            try
            {
                const countryData = await fetchCountry();
                setCountryList( countryData );
            } catch ( error )
            {
                console.error( "Error loading country:", error );
            }
        };

        load();

    }, [] )


    // fetch State

    useEffect( () =>
    {
        const load = async ( value ) =>
        {
            try
            {
                const stateData = await fetchState( value );
                setStateList( stateData );
            } catch ( error )
            {
                console.error( "Error loading country:", error );
            }
        };

        load( country != 'unset' ? country : 'india' );

    }, [ country ] )


    // fetch City

    useEffect( () =>
    {
        const load = async ( value1 , value2 ) =>
        {
            try
            {
                const cityData = await fetchCity( value1 , value2 );
                setCityList( cityData );
            } catch ( error )
            {
                console.error( "Error loading country:", error );
            }
        };

        load( country , state );

    }, [ state ] )


    // fetch Income

    useEffect( () =>
    {
        const load = async ( value ) =>
        {
            try
            {
                const incomeData = await fetchIncomeRange( value );
                setIncomeList( incomeData );
            } catch ( error )
            {
                console.error( "Error loading country:", error );
            }
        };

        load();

    }, [] )



    // open Close filters 

    useEffect( () =>
    {

        Array.from( document.getElementsByClassName( 'filter_head' ) ).forEach( ( el, i ) =>
        {
            el.addEventListener( 'click', () =>
            {
                const icon = el.getElementsByTagName( 'i' )[ 0 ];
                const radioSection = document.getElementsByClassName( 'radio_section' )[ i ];

                if ( icon.classList.contains( 'bi-plus-square-fill' ) )
                {
                    icon.classList.remove( 'bi-plus-square-fill' );
                    icon.classList.add( 'bi-dash-square-fill' );
                    radioSection.classList.add( 'radio_sec_active' );
                    setHeight( document.getElementsByClassName( 'filter' )[ 0 ].clientHeight );

                } else
                {
                    icon.classList.remove( 'bi-dash-square-fill' );
                    icon.classList.add( 'bi-plus-square-fill' );
                    radioSection.classList.remove( 'radio_sec_active' );
                    setHeight( document.getElementsByClassName( 'filter' )[ 0 ].clientHeight );

                }
            } );
        } );

    }, [] )


    // Spiritual Filter

    let spiritualFilter = ( value, el ) =>
    {
        if ( value == 'all' )
        {
            setSpiritualPaths( 'unset' );
            Array.from( document.getElementsByClassName( 'spirtual_path' )[ 0 ].getElementsByClassName( 'radio_card' ) ).forEach( ( item, i ) =>
            {
                if ( i == 0 )
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = true;
                } else
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = false;
                }
            } )
        } else
        {
            Array.from( document.getElementsByClassName( 'spirtual_path' )[ 0 ].getElementsByClassName( 'radio_card' ) ).forEach( ( item, i ) =>
            {
                if ( el.value == item.getElementsByTagName( 'input' )[ 0 ].value )
                {
                    setSpiritualPaths( value );
                    item.getElementsByTagName( 'input' )[ 0 ].checked = true;
                } else
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = false;
                }
            } )
        }
    }


    // Marital Filter

    let maritalFilter = ( value, el ) =>
    {
        if ( value == 'all' )
        {
            setMaritalStatus( 'unset' );
            Array.from( document.getElementsByClassName( 'marital_status' )[ 0 ].getElementsByClassName( 'radio_card' ) ).forEach( ( item, i ) =>
            {
                if ( i == 0 )
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = true;
                } else
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = false;
                }
            } )
        } else
        {
            Array.from( document.getElementsByClassName( 'marital_status' )[ 0 ].getElementsByClassName( 'radio_card' ) ).forEach( ( item, i ) =>
            {
                if ( el.value == item.getElementsByTagName( 'input' )[ 0 ].value )
                {
                    setMaritalStatus( value );
                    item.getElementsByTagName( 'input' )[ 0 ].checked = true;
                } else
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = false;
                }
            } )
        }
    }


    // Country Filter

    let countryFilter = ( value, el ) =>
    {
        if ( value == 'all' )
        {
            setCountry( 'unset' );
            Array.from( document.getElementsByClassName( 'country_status' )[ 0 ].getElementsByClassName( 'radio_card' ) ).forEach( ( item, i ) =>
            {
                if ( i == 0 )
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = true;
                } else
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = false;
                }
            } )
        } else
        {
            Array.from( document.getElementsByClassName( 'country_status' )[ 0 ].getElementsByClassName( 'radio_card' ) ).forEach( ( item, i ) =>
            {
                if ( el.value == item.getElementsByTagName( 'input' )[ 0 ].value )
                {
                    setCountry( value );
                    item.getElementsByTagName( 'input' )[ 0 ].checked = true;
                } else
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = false;
                }
            } )
        }
    }


    // State Filter

    let stateFilter = ( value, el ) =>
    {
        if ( value == 'all' )
        {
            setState( 'unset' );
            Array.from( document.getElementsByClassName( 'state_status' )[ 0 ].getElementsByClassName( 'radio_card' ) ).forEach( ( item, i ) =>
            {
                if ( i == 0 )
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = true;
                } else
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = false;
                }
            } )
        } else
        {
            Array.from( document.getElementsByClassName( 'state_status' )[ 0 ].getElementsByClassName( 'radio_card' ) ).forEach( ( item, i ) =>
            {
                if ( el.value == item.getElementsByTagName( 'input' )[ 0 ].value )
                {
                    setState( value );
                    item.getElementsByTagName( 'input' )[ 0 ].checked = true;
                } else
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = false;
                }
            } )
        }
    }



    // City Filter

    let cityFilter = ( value, el ) =>
    {
        if ( value == 'all' )
        {
            setCity( 'unset' );
            Array.from( document.getElementsByClassName( 'city_status' )[ 0 ].getElementsByClassName( 'radio_card' ) ).forEach( ( item, i ) =>
            {
                if ( i == 0 )
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = true;
                } else
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = false;
                }
            } )
        } else
        {
            Array.from( document.getElementsByClassName( 'city_status' )[ 0 ].getElementsByClassName( 'radio_card' ) ).forEach( ( item, i ) =>
            {
                if ( el.value == item.getElementsByTagName( 'input' )[ 0 ].value )
                {
                    setCity( value );
                    item.getElementsByTagName( 'input' )[ 0 ].checked = true;
                } else
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = false;
                }
            } )
        }
    }


    // Income Filter

    let incomeFilter = ( value, el ) =>
    {
        if ( value == 'all' )
        {
            setIncome( 'unset' );
            Array.from( document.getElementsByClassName( 'income_status' )[ 0 ].getElementsByClassName( 'radio_card' ) ).forEach( ( item, i ) =>
            {
                if ( i == 0 )
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = true;
                } else
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = false;
                }
            } )
        } else
        {
            Array.from( document.getElementsByClassName( 'income_status' )[ 0 ].getElementsByClassName( 'radio_card' ) ).forEach( ( item, i ) =>
            {
                if ( el.value == item.getElementsByTagName( 'input' )[ 0 ].value )
                {
                    setIncome( value );
                    item.getElementsByTagName( 'input' )[ 0 ].checked = true;
                } else
                {
                    item.getElementsByTagName( 'input' )[ 0 ].checked = false;
                }
            } )
        }
    }

    // Add Fav/Rem 

    let favRem = async ( vid, type, el ) =>
    {
        {
            await fetch( `${ baseUrl }/addFavRem`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify( { vid, 'uid': userDeatils.uniquecode, type } ),
            } ).then( response =>
            {
                if ( !response.ok )
                {
                    throw new Error( "Network response was not ok" );
                }
                return response.json();
            } )
                .then( data =>
                {
                    if ( data.data == 'add' )
                    {
                        el.setAttribute( 'value', 'yes' );
                        el.style.color = 'red';
                        el.style.textShadow = '0px 5px 10px rgb(251 0 10)';
                    } else
                    {
                        el.setAttribute( 'value', 'no' );
                        el.style.color = '#f6aab2';
                        el.style.textShadow = '0px 5px 10px rgb(209, 134, 137)';
                    }
                } )
                .catch( error =>
                {
                    console.error( "There was a problem with the fetch operation:", error );
                } );
        }
    }


    // Connect Now
    let connectUser = async ( vid, type, up , cu ) =>
    {
        {
            await fetch( `${ baseUrl }/connectUser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify( { vid, 'uid': userDeatils.uniquecode, type } ),
            } ).then( response =>
            {
                if ( !response.ok )
                {
                    throw new Error( "Network response was not ok" );
                }
                return response.json();
            } )
                .then( data =>
                {
                    if (data.data == 'add') {
                        document.getElementById(cu).style.display = 'none'
                        document.getElementById(up).style.display = 'flex'
                    } 
                } )
                .catch( error =>
                {
                    console.error( "There was a problem with the fetch operation:", error );
                } );
        }
    }


    // Get Connect Data

    useEffect(() => {
        let load = async () => {
            await fetch( `${ baseUrl }/getConnectUser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify( { 'uid': userDeatils.uniquecode } ),
            } ).then( response =>
            {
                if ( !response.ok )
                {
                    throw new Error( "Network response was not ok" );
                }
                return response.json();
            } )
                .then( data =>
                {
                    setConnectList(data.data);
                })
                .catch( error =>
                {
                    console.error( "There was a problem with the fetch operation:", error );
                } );
        }

        load();
    }, [userDeatils])



    // Get Partner Preferences Data

    useEffect(() => {
        const loadpref = async () => {
            try {
                if (userDeatils.uniquecode) {
                    let user_id = userDeatils.uniquecode;
                    const whereObj1 = { user_id: user_id };
                    const pref = await fetchData("*", "partner_preferences", whereObj1);
                    if (pref) {
                        setPartnerPreference(pref[0]);
                        setStartAge(pref[0].start_age)
                        setEndAge(pref[0].end_age)
                    }
                }
            } catch (error) {
                console.error("Error loading preferences:", error);
            }
        };
        loadpref();

    }, [userDeatils])


    let checkConnectUser = (value) => {
        let newData = connectList.filter((el , i) => {return el.visitor_id == value});
        return newData.length;
    }

    // Height 

    useEffect( () =>
    {
        setHeight( document.getElementsByClassName( 'filter' )[ 0 ].clientHeight );
    }, [ newMatches ] )

    return (
        <>
            <section className="w70 new_matches fcs-display m_auto">
                <div className="match_left_bx">
                    <div className="filter">
                        <h3><i className="bi bi-filter-circle-fill"></i> Filter</h3>

                        {/* Spiritual  */}

                        <div className="filter_card spirtual_path">
                            <div className="filter_head fcs-display w100">
                                <h6>Spirtual Path</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input type="checkbox" value="all" onClick={ ( e ) => spiritualFilter( 'all', e.target ) } />
                                    <label>All</label>
                                </div>
                                {
                                    Array.isArray( communityList ) && communityList.length != 0 ? (
                                        communityList.map( ( el, i ) => (
                                            <div className="radio_card" key={ i }>
                                                <input type="checkbox" value={ el.name } onClick={ ( e ) => spiritualFilter( el.name, e.target ) } />
                                                <label>{ el.name }</label>
                                            </div>
                                        ) )
                                    ) : (
                                        <p style={{fontSize: '13px'}}>Community Not Found</p>
                                    )
                                }
                            </div>
                        </div>

                        {/* Age  */}


                        <div className="filter_card age" >
                            <div className="filter_head fcs-display w100">
                                <h6>Age</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <div className="card_fil">
                                        <label>Start</label>
                                        <input type="number" min={ 18 } max={ 60 } value={ startAge } onChange={ ( e ) => { endAge > e.target.value && 18 <= e.target.value && 60 >= e.target.value ? setStartAge( e.target.value ) : setStartAge( startAge ) } } />
                                    </div>
                                    <div className="card_fil">
                                        <label>End</label>
                                        <input type="number" min={ 18 } max={ 60 } value={ endAge } onChange={ ( e ) => { startAge < e.target.value && 18 <= e.target.value ? setEndAge( e.target.value ) : setEndAge( endAge ) } } />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Marital  */}

                        <div className="filter_card marital_status">
                            <div className="filter_head fcs-display w100">
                                <h6>Marital Status</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input type="checkbox" value="all" onClick={ ( e ) => maritalFilter( 'all', e.target ) } />
                                    <label>All</label>
                                </div>
                                {
                                    Array.isArray( maritalList ) && maritalList.length != 0 ? (
                                        maritalList.map( ( el, i ) => (
                                            <div className="radio_card" key={ i }>
                                                <input type="checkbox" value={ el.name } onClick={ ( e ) => maritalFilter( el.name, e.target ) } />
                                                <label>{ el.name }</label>
                                            </div>
                                        ) )
                                    ) : (
                                        <p style={{fontSize: '13px'}}>Marital Status Not Found</p>
                                    )
                                }
                            </div>
                        </div>


                        {/* Income  */}

                        <div className="filter_card income_status">
                            <div className="filter_head fcs-display w100">
                                <h6>Income</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input type="checkbox" value="all" onClick={ ( e ) => incomeFilter( 'all', e.target ) } />
                                    <label>All</label>
                                </div>
                                {
                                    Array.isArray( incomeList ) && incomeList.length != 0 ? (
                                        incomeList.map( ( el, i ) => (
                                            <div className="radio_card" key={ i }>
                                                <input type="checkbox" value={ el.lable } onClick={ ( e ) => incomeFilter( el.lable, e.target ) } />
                                                <label>{ el.lable }</label>
                                            </div>
                                        ) )
                                    ) : (
                                        <p style={{fontSize: '13px'}}>Income Not Found</p>
                                    )
                                }
                            </div>
                        </div>

                        {/* Country  */}

                        <div className="filter_card country_status">
                            <div className="filter_head fcs-display w100">
                                <h6>Country</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input type="checkbox" value="all" onClick={ ( e ) => countryFilter( 'all', e.target ) } />
                                    <label>All</label>
                                </div>
                                {
                                    Array.isArray( countryList ) && countryList.length != 0 ? (
                                        countryList.map( ( el, i ) => (
                                            <div className="radio_card" key={ i }>
                                                <input type="checkbox" value={ el.country } onClick={ ( e ) => countryFilter( el.country, e.target ) } />
                                                <label>{ el.country }</label>
                                            </div>
                                        ) )
                                    ) : (
                                        <p style={{fontSize: '13px'}}>Country Not Found</p>
                                    )
                                }
                            </div>
                        </div>


                        {/* State  */}

                        <div className="filter_card state_status">
                            <div className="filter_head fcs-display w100">
                                <h6>State</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input type="checkbox" value="all" onClick={ ( e ) => stateFilter( 'all', e.target ) } />
                                    <label>All</label>
                                </div>
                                {
                                    Array.isArray( stateList ) && stateList.length != 0 ? (
                                        stateList.map( ( el, i ) => (
                                            <div className="radio_card" key={ i }>
                                                <input type="checkbox" value={ el.state } onClick={ ( e ) => stateFilter( el.state, e.target ) } />
                                                <label>{ el.state }</label>
                                            </div>
                                        ) )
                                    ) : (
                                        <p style={{fontSize: '13px'}}>Country Not Found</p>
                                    )
                                }
                            </div>
                        </div>


                        {/* City  */}

                        <div className="filter_card city_status">
                            <div className="filter_head fcs-display w100">
                                <h6>City</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input type="checkbox" value="all" onClick={ ( e ) => cityFilter( 'all', e.target ) } />
                                    <label>All</label>
                                </div>
                                {
                                    Array.isArray( cityList ) && cityList.length != 0 ? (
                                        cityList.map( ( el, i ) => (
                                            <div className="radio_card" key={ i }>
                                                <input type="checkbox" value={ el.city } onClick={ ( e ) => cityFilter( el.city, e.target ) } />
                                                <label>{ el.city }</label>
                                            </div>
                                        ) )
                                    ) : (
                                        <p style={{fontSize: '13px'}}>Country Not Found</p>
                                    )
                                }
                            </div>
                        </div>


                        


                    </div>
                </div>
                <div className="match_right_bx" style={ { height: `${ height }px` } }>
                <h4 style={{marginBottom: '10px' , fontSize:'12px'}}>This page will show profiles as per the partner preference that you've set Click here to change your <Link to='/myprofile#partnerPref'><b>Partner preference</b></Link></h4>
                    {
                        Array.isArray( newMatches ) && newMatches.length > 0 ? (
                            newMatches.map( ( el, i ) => (
                                <div className="card_matches w-100 fcs-display" key={ i }>
                                    <div className="img_bx">
                                        <Link to={ `/user/${ el.uniquecode }` }><img src={ el.profile_pic } onError={(e) => { e.target.onerror = null;  e.target.src = 'https://svgsilh.com/png-512/659651.png';}} alt="profile pic" /></Link>
                                    </div>
                                    <div className="content_bx">
                                    <Link to={ `/user/${ el.uniquecode }` }><h4>{ el.first_name } { el.last_name }  <i className="bi bi-shield-check"></i></h4></Link>
                                        <div className="cont_head w-100 fcs-display">
                                            <h6><i className="bi bi-chat-right-dots-fill"></i> Online 3h ago</h6>
                                            <div className="r_fav">
                                                <span></span>
                                                <i className="bi bi-heart-fill" value={ el.is_match == 'yes' ? 'yes' : 'no' } style={ el.is_match == 'yes' ? { color: 'red', textShadow: '0px 5px 10px rgb(251 0 10)' } : { color: '#f6aab2', textShadow: ' 0px 5px 10px rgb(209, 134, 137)' } } onClick={ ( e ) => { e.target.getAttribute( 'value' ) == 'no' ? favRem( el.uniquecode, 'add', e.target ) : favRem( el.uniquecode, 'rem', e.target ) } } ></i>
                                            </div>
                                        </div>
                                        <ul className="fcs-display w-100">
                                            <li>{ el.age } yrs, { el.height }"</li>
                                            <li>{ el.community }</li>
                                            <li>{ el.country }, { el.state }</li>
                                            <li>{ el.marital_status }</li>
                                            <li>{ el.mother_tongue }</li>
                                            <li>{ el.religion }</li>
                                        </ul>
                                        <p>{ el.about } <Link to={ `/user/${ el.uniquecode }` }>Show more.</Link></p>
                                    </div>
                                    <div className="connect_bx">

                                        {

                                        checkConnectUser(el.uniquecode) == 0 ?
                                   <>
                                        <div className="connect_profile w-100 fcc-display connectNow" id={`current${i}`} >
                                            <h6>Like this profile?</h6>
                                            <i className="bi bi-check-circle-fill" onClick={(e) => {connectUser( el.uniquecode, 'add', `upgrade${i}` , `current${i}` )}}></i>
                                            <p>Connect Now</p>
                                        </div>

                                        <div className="connected_profile w-100 fcc-display" id={`upgrade${i}`} style={{display: 'none'}}>
                                            <p>
                                                <a href="/mymatches">Upgrade</a>
                                                to Contact her directly
                                            </p>
                                            <button>
                                                <i className="bi bi-telephone-fill"></i>
                                                Call
                                            </button>
                                            <button>
                                                <i className="bi bi-whatsapp"></i>
                                                Whatsaap
                                            </button>
                                           
                                        </div>
                                   </>
                                        
                                        :
                                        <div className="connected_profile w-100 fcc-display upgrabeUser">
                                            <p>
                                                <a href="/mymatches">Upgrade</a>
                                                to Contact her directly
                                            </p>
                                            <button>
                                                <i className="bi bi-telephone-fill"></i>
                                                Call
                                            </button>
                                            <button>
                                                <i className="bi bi-whatsapp"></i>
                                                Whatsaap
                                            </button>
                                          
                                        </div>

                                  }
                                    </div>
                                   
                                </div>
                            ) )

                        ) : (
                            <p style={ { textAlign: "center", marginTop: '20px' } }>No matches found.</p>
                        )
                    }
                </div>
            </section>
        </>
    )
}

export default MyMatches;